<template>
  <div>
    <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
      <div
        class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
      >
        Tax Details
      </div>
      <div class="py-24 flex items-center justify-center" v-if="isLoading">
        <Loader />
      </div>
      <div class="py-3 px-6" v-else>
        <FormulateForm>
          <div class="grid grid-cols-2 gap-x-6">
            <div class="mb-4">
              <FormulateInput
                class="w-full custumTaxInput"
                label="Tax Name"
                placeholder="tax name"
                validation="required"
                type="text"
                v-model="taxDetails.tax_name"
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
                @input="(value) => handleTaxName(value, 'taxName')"
              />
              <span
                v-if="taxDetails.tax_name && taxNameError?.hasError"
                class="text-red-500 text-sm"
                >{{ taxNameError?.errorMsg }}</span
              >
            </div>
            <div class="p-2">
              <h1 class="flex gap-2">
                Rate
                <span class="text-red-500">{{ $t('general.asterisk') }}</span>
              </h1>
              <label class="input-group input-group-md">
                <span>%</span>
                <input
                  type="text"
                  placeholder="rate"
                  class="input input-bordered input-md h-10 w-full"
                  v-model="taxDetails.rate"
                  @keypress="isNumber"
                  @input="
                    ($event) => handleTaxName($event.target.value, 'rate')
                  "
                />
              </label>
              <span v-if="rateError?.hasError" class="text-red-500 text-sm">{{
                rateError?.errorMsg
              }}</span>
            </div>
            <FormulateInput
              class="w-full"
              label="Tax Description"
              placeholder="description"
              v-model="taxDetails.tax_description"
              type="text"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
            />
          </div>
        </FormulateForm>
        <div class="flex justify-end max-w-full">
          <Button
            :disabled="!changeMade"
            @click="editMode ? editTaxDetails() : saveTaxDetails()"
            class="btn-primary float-right mt-4"
            text="Save & Next"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@shared/loader";
import Button from "@/components/button";
import {isNumber} from '../../../../plugins/functions.js'
import {
  saveTax,
  getTaxDetails,
  editTaxDetails,
  validNumber,
  vildateTaxName,
} from "../../services";
export default {
  name: "taxDetails",
  data: () => {
    return {
      changeMade: false,
      isLoading: false,
      showError: false,
      taxDetails: {
        tax_name: null,
        tax_description: null,
        rate: null,
      },
      rateError: {
        hasError: false,
        errorMsg: "",
      },
      taxNameError: {
        hasError: false,
        errorMsg: "",
      },
    };
  },
  components: { Loader, Button },
  computed: {
    editMode() {
      return this.$route.params.id;
    },
  },
  watch: {
    taxDetails: {
      deep: true,
      handler() {
        if (!this.isLoading) {
          this.changeMade = true;
        }
      },
    },
  },
  async mounted() {
    if (this.editMode) {
      this.isLoading = true;
      await this.setTaxDetails();
      this.isLoading = false;
    }
  },
  methods: {
    isNumber,
    handleTaxName(value, name) {
      if (name == "taxName") {
        if (this.showError && value && !vildateTaxName(value)) {
          this.taxNameError = { hasError: true, errorMsg: "Invalid tax name!" };
        } else {
          this.taxNameError = { hasError: false, errorMsg: "" };
        }
      }
      if (name == "rate") {
        if (value > 100) {
          this.taxDetails.rate = this.taxDetails.validRate
        } else if (value <= 100) {
          this.taxDetails.validRate = this.taxDetails.rate
          if (this.showError && value && !validNumber(value)) {
            this.rateError = {
              hasError: true,
              errorMsg: "Only number is allowed!",
            };
          } else {
            this.rateError = { hasError: false, errorMsg: "" };
          }
        }
      }
    },
    async setTaxDetails() {
      const { data } = await getTaxDetails(this.$route.params.id);
      this.taxDetails = data;
      this.taxDetails.validRate = data.rate
    },
    handleError() {
      let invalidData = false;
      if (!this.taxDetails?.tax_name ) {
        this.taxNameError = { hasError: true, errorMsg: "Tax name is required!" };
        return (invalidData = true);
      } else {
        this.taxNameError = { hasError: false, errorMsg: "" };
      }
      if (
        this.taxDetails?.tax_name &&
        !vildateTaxName(this.taxDetails?.tax_name)
      ) {
        this.taxNameError = { hasError: true, errorMsg: "Invalid tax name!" };
        return (invalidData = true);
      } else {
        this.taxNameError = { hasError: false, errorMsg: "" };
      }
      if (!this.taxDetails?.rate) {
        this.rateError = {
          hasError: true,
          errorMsg: "Rate is required field!",
        };
        return (invalidData = true);
      } else if (!validNumber(this.taxDetails?.rate)) {
        this.rateError = {
          hasError: true,
          errorMsg: "Only number is allowed!",
        };
        return (invalidData = true);
      } else if(this.taxDetails?.rate < 0){
        this.rateError = {
          hasError: true,
          errorMsg: "Value should be greater then 0.",
        };
      } else{
        this.rateError = { hasError: false, errorMsg: "" };
      }
      return invalidData;
    },
    async saveTaxDetails() {
      this.changeMade = false;
      this.showError = true;
      let invalidData = this.handleError();
      if (!this.taxDetails.tax_name || !this.taxDetails.rate || invalidData) {
        this.$toast.error(`${this.taxNameError.hasError 
          ? this.taxNameError.errorMsg 
          :  this.rateError.errorMsg
        }`);
        return;
      } else if (!validNumber(this.taxDetails.rate)) {
        this.$toast.error("Only numbers are allowed for tax Rate!");
        return;
      } else if(this.taxDetails.rate < 0){
        this.$toast.error("Tax rate should be greater then 0.");
        return;
      }
      const { data } = await saveTax(this.taxDetails);
      this.$toast.success("Tax saved successfully");
      const result = {
        taxId: data.tax_id,
        taxRate: data?.rate,
        nextSection: "tenant_assign",
      };
      this.$emit("handleNextSection", result);
    },
    async editTaxDetails() {
      this.changeMade = false;
      let invalidData = false;
      if (invalidData) {
        this.$toast.error("Please fill all required fields");
      } else {
        if (this.$route.params.id) {
          this.taxDetails.id = this.$route.params.id;
        }
        await editTaxDetails(this.taxDetails);
        this.$toast.success("Tax updated successfully");
        const result = {
          taxId: this.taxDetails?.id,
          taxRate: this.taxDetails?.rate,
          nextSection: "tenant_assign",
        };
        this.$emit("handleNextSection", result);
      }
    },
  },
};
</script>
<style>
.formulate-input[data-classification="text"] input {
  border-radius: 0.5rem;
}
.custumTaxInput {
  margin-bottom: 0;
}
</style>
